/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Inner from "../zzz/layout/pageInner/Inner"
import { graphql, useStaticQuery } from "gatsby"
import SvgIcon from "../zzz/atoms/icons/svg-icon"
import Phone from "../zzz/atoms/icons/files/phone.jsx"
import Envelope from "../zzz/atoms/icons/files/envelope.jsx"
import Calendar from "../zzz/atoms/icons/files/calendar.jsx"
import LocationPin from "../zzz/atoms/icons/files/location-pin.jsx"
import Directions from "../zzz/atoms/icons/files/directions.jsx"
import { LinkWrapper as Link } from "../utils/linkWrapper"
import { path } from "../lib/util"
import FeaturedAreaLink from "../zzz/molecules/mainNavigation/contactMenu/FeaturedAreaLink"
import { formatEmailToLink, formatPhoneToLink } from "../lib/util"

// A single branch page
const BranchPage = ({ pageContext, location, data }) => {
  const post_id = pageContext.brancId
  const seo = pageContext.seo

  const branches = path(["allLocalWpGraphQlBranches", "nodes"], data)
  const {
    branchname,
    distributionOnly,
    tel1,
    tel2,
    tel3,
    address,
    email,
    hours,
    link,
    embed,
    fluid,
  } = pageContext
  const tel = [tel1, tel2, tel3]
  const filtered = tel.filter((n) => n)
  const splitLines = (str) => str.split(/\r?\n/)
  const splitHours = splitLines(hours)

  const img = path(
    [
      "branch",
      "branch_details",
      "image",
      "imageFile",
      "localFile",
      "childImageSharp",
      "fluid",
    ],
    data
  )

  return (
    <Layout location={location}>
      <SEO title={branchname} location={location} post_id={post_id} seo={seo} />
      <Heading
        location={location}
        fluid={img || data.file.childImageSharp.fluid}
      >
        <Wrap>
          <Inner>
            <Flex>
              <Title>{branchname}</Title>
            </Flex>
          </Inner>
        </Wrap>
      </Heading>
      <Grey>
        <Inner>
          <Grid>
            <InfoBox>
              <div className="title">
                <SvgIcon
                  SvgComponent={Phone}
                  hue="success"
                  shade="fontColor"
                  size="md"
                />
                Contact Numbers
              </div>
              {filtered.map((phone) => (
                <item
                  key={phone}
                  dangerouslySetInnerHTML={{
                    __html: formatPhoneToLink(phone),
                  }}
                />
              ))}
            </InfoBox>
            <InfoBox>
              <div className="title">
                <SvgIcon
                  SvgComponent={Envelope}
                  hue="success"
                  shade="fontColor"
                  size="md"
                />
                Email
              </div>
              <item
                dangerouslySetInnerHTML={{ __html: formatEmailToLink(email) }}
              />
            </InfoBox>
            <InfoBox>
              <div className="title">
                <SvgIcon
                  SvgComponent={Calendar}
                  hue="success"
                  shade="fontColor"
                  size="md"
                />
                Operating Hours
              </div>
              <div className="operating-hours">
                {splitHours.map((hours) => {
                  let cols = hours.split("|")
                  return (
                    <item key={hours}>
                      <div className="day">{cols[0]}</div>
                      <div className="hours">{cols[1]}</div>
                    </item>
                  )
                })}
              </div>
            </InfoBox>
          </Grid>
        </Inner>
      </Grey>
      {!distributionOnly && (
        <>
          <Address>
            <Inner>
              <div className="address">
                <SvgIcon
                  SvgComponent={LocationPin}
                  hue="success"
                  shade="fontColor"
                  size="md"
                />
                <div>{address}</div>
              </div>
              <DirectionsButton>
                <SvgIcon
                  SvgComponent={Directions}
                  hue="white"
                  shade="000"
                  size="md"
                />
                <div className="label">XXKM Drive</div>
              </DirectionsButton>
            </Inner>
          </Address>

          <iframe
            src={embed}
            width="100%"
            height="600"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </>
      )}

      <Grey lessPadding={distributionOnly}>
        <Inner>
          <TitleBox>
            <h2>Other Stores</h2>
            <p>Below is a list of some of our other branches.</p>
          </TitleBox>
          <Grid>
            {branches.map((node) => (
              <FeaturedAreaLink
                key={node.id}
                raised
                branch={node}
                title={node.title}
                phone_1={node.branch_details.telephoneNumbers.phone1}
                phone_2={node.branch_details.telephoneNumbers.phone2}
                address={node.branch_details.address}
                slug={node.slug}
                hours={node.branch_details.operatingHours}
                imgBorder
              />
            ))}
          </Grid>
        </Inner>
      </Grey>
    </Layout>
  )
}

export default BranchPage

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  h1,
  p {
    text-align: center;
    margin-top: 20px;
    color: #223247;
  }
  h1 {
    border-bottom: 3px solid red;
  }
`

const DirectionsButton = styled.div`
  border-radius: 4px;
  background: transparent;
  font-family: "Roboto";
  font-size: 16px;
  border: 1px solid #ffffff;
  color: white;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  white-space: pre;
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: center;
  justify-items: center;
  .icon {
    border-right: 1px solid #ffffff;
    padding: 2px;
  }
  .label {
    padding: 5px 10px;
  }
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`

const Address = styled.div`
  background-color: #223247;
  padding: 20px 0;
  color: white;
  font-family: "Roboto";
  font-size: 18px;
  line-height: 26px;
  > div {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: center;
  }
  .address {
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: center;
    margin-right: 40px;
  }
  @media (max-width: 600px) {
    > div {
      grid-template-columns: 1fr;
    }
    .address {
      margin-right: 0;
    }
  }
`

const InfoBox = styled.div`
  border-radius: 4px;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  overflow: hidden;
  .title {
    background-color: #223247;
    color: white;
    padding: 10px;
    color: #ffffff;
    font-family: "Roboto";
    font-size: 23px;
    line-height: 29px;
    > span {
      margin-right: 10px;
    }
  }
  item {
    word-break: break-word;
    display: block;
    padding: 10px;
    color: #223247;
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 300;
    border-bottom: 1px dashed #22324738;
    span {
      color: ${({ theme }) => theme.colors.tusk["080"]};
    }
  }
  .operating-hours item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .hours {
      justify-self: flex-end;
    }
  }
`

const Heading = styled(BackgroundImage)``

const Grey = styled.div`
  background: #f6f6f6;
  padding: 60px 0;
  padding: ${({ lessPadding }) => (lessPadding ? "0 0 60px 0;" : "60px 0")};
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
`

const Wrap = styled.div`
  background: linear-gradient(180deg, #00193a2e 0%, rgba(0, 10, 23, 0.92));
  min-height: 300px;
`

const Title = styled.h1`
  color: #ffffff;
  font-family: "Roboto";
  font-size: 48px;
  font-weight: 300;
  line-height: 57px;
`

export const PLACEHOLDER = graphql`
  query($slug: String!) {
    branch: localWpGraphQlBranches(slug: { eq: $slug }) {
      branch_details {
        image {
          sourceUrl
          mediaItemId
          imageFile {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    allLocalWpGraphQlBranches(limit: 3) {
      nodes {
        branch_details {
          image {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          address
          addressLink
          branchName
          email
          embedLink
          operatingHours
          telephoneNumbers {
            phone1
            phone2
            phone3
          }
        }
        slug
      }
    }

    file(relativePath: { eq: "team-image-placeholder.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
