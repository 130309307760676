import React from "react"

const IconDirections = ({ fill }) => (
  <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.74 6.38c.35.34.35.9 0 1.24l-6.12 6.12a.87.87 0 01-1.24 0L.26 7.62a.88.88 0 010-1.24L6.38.26a.87.87 0 011.24 0l6.12 6.12zM7 13.12L13.12 7 7 .88.88 7 7 13.12zm2.05-7l-1.5-1.37a.22.22 0 010-.31l.29-.32c.08-.1.22-.1.3-.02l2.15 1.98a.66.66 0 010 .96L8.15 9.02a.22.22 0 01-.31-.01l-.3-.32a.22.22 0 01.01-.31L9.05 7H5.69c-.24 0-.44.2-.44.44v1.1c0 .11-.1.21-.22.21H4.6a.22.22 0 01-.22-.22v-1.1c0-.72.6-1.3 1.32-1.3h3.36z"
      fill={fill}
    />
  </svg>
)

export default IconDirections
